<template>
  <div class="grid-x">
    <div class="cell small-12 medium-6 medium-offset-3">
      <!--
      <div class="content-box">
        <p class="text-yellow text-bold mb-0">
          The page content has been updated.
          <button @click="reloadSite" class="button">Click to update</button>
        </p>
      </div>
      -->
      <div class="content-box">
        <p class="text-yellow text-bold mb-0">
          This alert card contains important safety information that you need to be aware of before,
          during and after stopping treatment with TYSABRI.
        </p>
        <ul>
          <li>
            Show this card to any doctor involved with your treatment, not only to your neurologist.
          </li>
          <li>
            Please read the TYSABRI 'Patient Leaflet' carefully before you start using this
            medicine.
          </li>
          <li>
            Keep this card with you during TYSABRI treatment and 6 months after the last dose of
            TYSABRI, since side effects may occur even after you have stopped treatment with
            TYSABRI.
          </li>
          <li>
            Show this card to your partner or caregivers. They might see symptoms of PML that you
            might not notice, such as changes in mood or behaviour, memory lapses, speech and
            communication difficulties. You should remain aware of symptoms that might arise for up
            to 6 months after stopping TYSABRI treatment.
          </li>
        </ul>
      </div>
      <div class="content-box">
        <p class="text-green text-bold mb-0">
          Prior to treatment with TYSABRI
        </p>
        <ul>
          <li>
            You should not be treated with TYSABRI if you have a serious problem with your immune
            system.
          </li>
          <li>
            You should not take any other long-term medicines for your multiple sclerosis while
            receiving TYSABRI.
          </li>
        </ul>
      </div>
      <div class="content-box">
        <p class="text-blue text-bold mb-0">
          During treatment with TYSABRI
        </p>
        <p class="text-bold mb-0">
          Progressive Multifocal Leukoencephalopathy (PML)
        </p>
        <p>
          PML, a rare brain infection, has occurred in patients who have been given TYSABRI. PML
          usually leads to severe disability or death.
        </p>
        <p>
          The risk of PML appears to increase with treatment duration, especially beyond 2 years.
        </p>
        <p>
          The symptoms of PML may be similar to an MS relapse. Therefore, if you believe your MS is
          getting worse or if you notice any new symptoms while you are on TYSABRI treatment or for
          up to 6 months after stopping TYSABRI treatment, it is very important that you speak to
          your doctor as soon as possible. PML symptoms generally develop more slowly than those
          associated with an MS relapse (over days or weeks), and may be similar to your MS
          symptoms.
        </p>
        <p class="text-bold mb-0">
          Signs include:
        </p>
        <ul>
          <li>Changes in mental ability and concentration,</li>
          <li>Behavioural changes,</li>
          <li>Weakness on one side of the body,</li>
          <li>Vision problems,</li>
          <li>New neurological symptoms that are unusual for you.</li>
        </ul>
        <p>
          Management of PML requires immediately stopping TYSABRI treatment.
        </p>
      </div>
      <div class="content-box">
        <p class="text-bold mb-0">
          Serious infections
        </p>
        <p>
          Other serious infections may occur with TYSABRI. Speak to your doctor as soon as possible
          if you think you have developed a severe, persistent infection, for example a persistent
          fever.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Content',
  methods: {
    async handleHardReload(url) {
      await fetch(url, {
        headers: {
          Pragma: 'no-cache',
          Expires: '-1',
          'Cache-Control': 'no-cache',
        },
      });
      window.location.href = url;
      window.location.reload();
    },
    reloadSite() {
      this.handleHardReload(window.location.href);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  background-color: #FFF;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
}
.button {
  float: right;
  border-radius: 3px;
  padding: 6px;
  background-color: #6b8db7;
  font-weight: bold;
  &:hover {
    background-color: darken(#6b8db7, 20%);
  }
}
</style>
