<template>
  <div id="app">
    <app-loading-screen></app-loading-screen>
    <app-header @installApp="installApp" :promoteInstall="showInstallButton"></app-header>
    <app-content></app-content>
    <app-yellow-box></app-yellow-box>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Content from '@/components/Content.vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import YellowBox from '@/components/YellowBox.vue';
import axios from './axios';

export default {
  name: 'App',
  components: {
    appContent: Content,
    appFooter: Footer,
    appHeader: Header,
    appLoadingScreen: LoadingScreen,
    appYellowBox: YellowBox,
  },
  data() {
    return {
      deferredPrompt: null,
      showInstallButton: false,
    };
  },
  methods: {
    record() {
      axios.get('/downloads/create/1.json');
    },
    async installApp() {
      this.deferredPrompt.prompt();
      const { outcome } = await this.deferredPrompt.userChoice;
      console.log(outcome);
      this.deferredPrompt = null;
      this.record();
    },
  },
  mounted() {
    axios.get('/downloads/create/0.json');
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showInstallButton = true;
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
      this.showInstallButton = false;
      console.log('PWA was installed');
    });
  },
};
</script>

<style lang="scss">
html, body, h2,h2,h3,h4,h5,h6,p,ul,li,ol,span,i {
  font-family: 'Montserrat', 'Arial', sans-serif;
}
body {
  background-color: #e6e6e7;
  padding-top: 50px;
  padding-bottom: 120px;
}
.mb-0 {
  margin-bottom: 0;
}
.text-yellow {
  color: #ffb300;
}
.text-green {
  color: #72b764;
}
.text-blue {
  color: #6b8db7;
}
.text-bold {
  font-weight: 700;
}
.text-upper {
  text-transform: uppercase;
}
.text-small {
  font-size: 8px;
}
.bg-grey {
  background-color: #e6e6e7;
}
.bg-white {
  background-color: #FFF;
}
@media all and (min-width: 40em) {
  .text-small {
    font-size: 14px;
  }
}
</style>
